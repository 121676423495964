import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

export default function VideoModal({
  isOpen,
  closeCallback,
  source,
  thumbnail,
}) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          closeCallback();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="md:relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] md:left-0 md:translate-y-0 md:translate-x-0 md:top-0 shadow-xl transition-all sm:my-8 w-full sm:w-2/3 sm:p-6">
                <div className="">
                  <div className="flex justify-end pb-2">
                    <button
                      type="button"
                      className="flex   justify-center rounded-md bg-[#73589B] text-white"
                      onClick={() => {
                        setOpen(false);
                        closeCallback();
                      }}
                    >
                      <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                    </button>
                  </div>

                  <div>
                    <video width="100%" controls poster={thumbnail}>
                      <source src={source} type="video/wmv" />
                      <source src={source} type="video/mp4" />
                      <source src={source} type="video/ogg" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
