import axios from "axios";

/**
 * Posts a recording status payload to the server.
 *
 * @param {object} payload - The recording status payload to post.
 *
 * @returns {Promise} Promise that resolves to the server response.
 */
export const postRecordingStatus = async (payload) => {
  try {
    // console.log("called");
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      process.env.REACT_APP_TOKEN_URL,
      payload,
      {
        headers,
      }
    );
    const postResponse = response;

    // console.log("postResponse", postResponse);
  } catch (error) {
    console.log(error);
  }
};

/**
 * runPixelStreamInstance starts a Pixel Streaming instance by executing server and player commands.
 * It makes an HTTP POST request to run the commands on the server.
 * The response contains process IDs and port number.
 * Returns a promise that resolves to an object with process IDs and port.
 * Catches any errors and logs them.
 */
export const runPixelStreamInstance = async (tempID) => {
  try {
    const serverStartCommand = `C:\\Users\\Administrator\\Downloads\\Test34\\Windows\\Stella\\Samples\\PixelStreaming\\WebServers\\SignallingWebServer\\platform_scripts\\cmd\\Start_SignallingServer.ps1 --HttpPort +http+ --HttpsPort +port+ --StreamerPort ++++ --SFUPort +++- `;

    const playerStartCommand =
      "C:\\Users\\Administrator\\Downloads\\Test34\\Windows\\OneBHK.exe";

    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}:4000/runCommand`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ServerCommand: serverStartCommand,

          PlayerCommand: playerStartCommand,
          tempID: tempID,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(
        `Server returned ${response.status} ${response.statusText}`
      );
    }

    const data = await response.json();

    return {
      playerProcessId: data.playerProcessId,
      serverProcessId: data.serverProcessId,
      httpsPort: data.httpsPort,
      streamerPort: data.streamerPort,
      httpPort: data.httpPort,
    };
  } catch (error) {
    console.error("Error:", error.message);
    return { message: "No more slots are available please try later." };
  }
};

/**
 * closePixelStreamInstance closes a running Pixel Streaming instance by sending a request
 * to terminate the server and player processes.
 *
 * It takes the serverProcessId and playerProcessId as arguments.
 *
 * It makes a POST request to a /closeInstance endpoint with the process IDs.
 *
 * It returns a promise that resolves when the processes are closed.
 *
 * Any errors are caught and logged.
 */
export const closePixelStreamInstance = async (
  serverProcessId,
  playerProcessId,
  httpsPort,
  streamerPort,
  httpPort,
  tempID
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}:4000/closeInstance`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          serverProcessId: serverProcessId,
          playerProcessId: playerProcessId,
          httpsPort: httpsPort,
          streamerPort: streamerPort,
          httpPort: httpPort,
          tempID: tempID,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(
        `Server returned ${response.status} ${response.statusText}`
      );
    }

    const data = await response.json();
    // console.log("Manual close");
    window.localStorage.setItem("Profile", "");
    window.sessionStorage.setItem("token", "");


    return;
  } catch (error) {
    console.error("Error:", error.message);
  }
};
