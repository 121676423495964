// Copyright Epic Games, Inc. All Rights Reserved.
import React, { useEffect, useRef, useState } from "react";
import {
  Config,
  AllSettings,
  PixelStreaming,
} from "@epicgames-ps/lib-pixelstreamingfrontend-ue5.2";

export interface PixelStreamingWrapperProps {
  initialSettings?: Partial<AllSettings>;
  selectedStream: Function;
}

export const PixelStreamingWrapper = ({
  initialSettings,
  selectedStream,
}: PixelStreamingWrapperProps) => {
  const videoParent = useRef<HTMLDivElement>(null);
  const [pixelStreaming, setPixelStreaming] = useState<PixelStreaming>();
  const [character, setCharacter] = useState(-1);
  const [clickToPlayVisible, setClickToPlayVisible] = useState(true);
  const [loader, setLoader] = useState(false);
  const [tempuserId, setTempuserId] = useState(initialSettings?.StreamerId);

  useEffect(() => {
    if (videoParent.current) {
      const config = new Config({ initialSettings });
      const streaming = new PixelStreaming(config, {
        videoElementParent: videoParent.current,
      });

      streaming.addEventListener("playStreamRejected", () => {
        setClickToPlayVisible(true);
      });
      streaming.addResponseEventListener(
        "handle_responses",
        (response: string) => {
          handleUnrealResponse(response, streaming); // Pass streaming as an argument
          console.log(response);
        }
      );
      setPixelStreaming(streaming);
      streaming?.play();
      // Cleanup function
      return () => {
        streaming.disconnect();
        // Additional cleanup logic here
      };
    }
  }, []);

  const handleUnrealResponse = (
    response: string,
    stream: PixelStreaming | undefined
  ) => {
    switch (response) {
      // case "StreamActive":
      //   stream?.emitUIInteraction(
      //     `{"userid":"${tempuserId}","character":"${character}","mode":"${
      //       window.innerWidth > 540 ? "landscape" : "portrait"
      //     }"}`
      //   );
        // console.log(
        //   `{"userid":"${tempuserId}","character":"${character}","mode":"${
        //     window.innerWidth > 540 ? "landscape" : "portrait"
        //   }"}`
        // );
        // break;
        case "StellaChatOn":
          selectedStream({speak: false});
        break;
        case "StellaChatClose":
          selectedStream({speak: true});
        break;
      default:
        break;
    }
  };

  const people = [
    {
      name: "Stella Lee",
      imageUrl: "../assets/characters/lee1.jpg",
    },
    {
      name: "Stella Mishra",
      imageUrl: "../assets/characters/mishra1.jpg",
    },
    {
      name: "Stella George",
      imageUrl: "../assets/characters/george1.jpg",
    },
    {
      name: "Stella Adams",
      imageUrl: "../assets/characters/adams1.jpg",
    },
  ];

  function execute(id: number) {
    setTimeout(() => {
      // handleUnrealResponse("StreamActive", pixelStreaming);
      pixelStreaming?.emitUIInteraction(
        `{"userid":"${tempuserId}","character":"${id}","mode":"${
          window.innerWidth > 540 ? "landscape" : "portrait"
        }"}`
      );
    }, 2000);
    setTimeout(() => {
      selectedStream({
        selected: true,
        name: people[id - 1].name,
        image: people[id - 1].imageUrl,
      });
      setLoader(false);
      setClickToPlayVisible(false);
    }, 8000);
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      {loader && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            zIndex: 1000000,
            backgroundColor: "rgb(0, 0, 0, 0.5)",
          }}
        >
          <div className="pulse before:bg-violet-800 after:border-violet-800 top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%]"></div>
        </div>
      )}
      {clickToPlayVisible && (
        <div
          style={{
            position: "absolute",
            // top: 0,
            // left: 2,
            // right:2,
            width: "99.5%",
            height: "100%",
            zIndex: 10000,
            // backgroundColor: "#DFE1E5",
            // paddingLeft: "3px",
            // paddingRight: "3px",
          }}
          className="left-1/2 transform -translate-x-1/2"
        >
          <div className="flex lg:p-10 bg-[#DFE1E5]   gap-10 h-full justify-center items-center">
            <div className="bg-white h-full w-full rounded-2xl pt-12 lg:p-12 flex flex-col justify-center items-center">
              <h2 className="text-3xl px-3  lg:px-8 text-center font-bold  text-gray-900 sm:text-4xl">
                Choose Your Stella Avatar
              </h2>
              <ul
                role="list"
                className="flex my-10  pt-8 p-6 overflow-auto gap-10 justify-center items-center flex-wrap w-full    "
              >
                {people.map((person, index) => (
                  <li
                    key={person.name}
                    onClick={() => {
                      // pixelStreaming?.play();
                      setLoader(true);
                      execute(index + 1);
                      setCharacter(index + 1);
                    }}
                    className={`w-32 lg:w-56 ${
                      character === index + 1 && " shadow-lg shadow-[#73589B]"
                    } relative flex cursor-pointer items-center hover:transition-all hover:shadow-[#73589B] hover:shadow-lg  rounded-2xl shadow-md justify-center flex-col `}
                  >
                    <img
                      className="aspect-[1/1]  rounded-2xl object-cover"
                      src={person.imageUrl}
                      alt=""
                    />
                    <h3 className="my-2 text-lg font-semibold leading-8  text-gray-900">
                      {person.name}
                    </h3>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          width: "100%",
          height: "100%",
        }}
        //  className="z-[-10000]"
        ref={videoParent}
      />
    </div>
  );
};
