// Copyright Epic Games, Inc. All Rights Reserved.
import React, { useEffect, useState, useRef } from "react";
import { PixelStreamingWrapper } from "./PixelStreamingWrapper.tsx";

const PixelStreaming = ({ PORT, tempID, selectedStreamMain }) => {
  const [stream, setstream] = useState(false);

  useEffect(() => {
    if (PORT !== undefined) {
      setstream(true);
    }
    // //console.log("port", PORT);
  }, [PORT]);

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {stream && (
        <PixelStreamingWrapper
          initialSettings={{
            AutoPlayVideo: true,
            AutoConnect: true,
            ss: `${process.env.REACT_APP_WEBSOCKET_URL}:${PORT}`,
            StartVideoMuted: false,
            HoveringMouse: true,
            StreamerId: `${tempID}`,
            MatchViewportRes: true,
            MouseInput: true,
            TouchInput: false,
            preferSFU: true,
            WebRTCMaxBitrate: 2000000,
          }}
          selectedStream={(data) => {
            selectedStreamMain(data);
          }}
        />
      )}
    </div>
  );
};

export default PixelStreaming;
