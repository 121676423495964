import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Dialog } from "@headlessui/react";
import { XMarkIcon, CameraIcon } from "@heroicons/react/24/outline";
import "../index.css";
import { CircularProgress } from "@mui/material";
import OtpInput from "../Modals/OtpInput";
import imageCompression from "browser-image-compression";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { signInAnonymously } from "firebase/auth";
import { auth, storage } from "../firebaseConfig";
import dayjs from "dayjs";

export default function HomePage({
  profile,
  loginCallback,
  response,
  updateUserDetails,
}) {
  const uploadInputFileRef = useRef(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [response_message, setResponse_message] = useState("");
  const [isLogin, setisLogin] = useState(false);
  const [otpInput, setOtpInput] = useState(false);
  const [timer, setTimer] = useState(30);
  const [resendotp, setResendOtp] = useState(false);
  const [login, setLogin] = useState(true);

  const [userDetails, setUserDetails] = useState({
    showProfileEditPanel: false,
    displayName: "",
    name: "",
    profileImg: "",
    mobileNo: "",

    updatedDisplayName: "",
    updatedName: "",
    updatedProfileImg: "",
    updatedMobileNo: "",
  });
  const [disableDetailsSubmit, setDisableDetailsSubmit] = useState(false);

  useEffect(() => {
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        console.log("SIGNED IN");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("ERROR", errorMessage);
      });

    // ////console.log("auth", auth);
  }, []);

  useEffect(() => {
    if (response === "Incorrect Password") {
      setResponse_message(response);
      setTimeout(() => {
        setResponse_message("");
      }, 3000);
      setisLogin(false);
    } else if (response === "Login Successful") {
      setOtpInput(true);
      setResendOtp(true);
      setLogin(false);
    }
  }, [response]);

  useEffect(() => {
    if (resendotp) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [resendotp]);

  useEffect(() => {
    if (timer === 0) {
      setResendOtp(false);
    }
  }, [timer]);

  useEffect(() => {
    const {
      showProfileEditPanel,
      updatedDisplayName,
      updatedName,
      updatedProfileImg,
    } = userDetails;

    if (
      showProfileEditPanel &&
      updatedDisplayName !== "" &&
      updatedDisplayName.length > 0 &&
      updatedName !== "" &&
      updatedName.length > 0 &&
      updatedName !== "User" &&
      updatedProfileImg !== "" &&
      updatedProfileImg !==
        "https://app.uwc.world/Content/App/assets/images/profile-img.png"
    ) {
      setDisableDetailsSubmit(false);
    } else {
      setDisableDetailsSubmit(true);
    }
  }, [userDetails]);

  const handleSignIn = () => {
    if (email === "") {
      setResponse_message("Email id field is required!");
      setisLogin(false);
      setTimeout(() => {
        setResponse_message("");
      }, 3000);
      return;
    } else {
      var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
      if (!pattern.test(email)) {
        setisLogin(false);
        setResponse_message("Please enter a valid email id!");
        setTimeout(() => {
          setResponse_message("");
        }, 3000);
        return;
      }
      loginCallback({ email: email });
    }
    setisLogin(true);
  };

  const handleComplete = (otp) => {
    //////console.log("Entered OTP:", otp);
    const postRecordingStatus = async () => {
      try {
        // ////console.log("called");
        const headers = {
          "Content-Type": "application/json",
        };
        const response = await axios.post(
          process.env.REACT_APP_OTP_ACTION_URL,
          { Email: email, OTP: otp },
          {
            headers,
          }
        );
        const postResponse = response;

        ////console.log("postResponse", postResponse);
        if (postResponse?.status === 200) {
          setOtpInput(false);
          const profileItem = profile;
          // JSON.parse(
          //   window.localStorage.getItem("Profile")
          // );
          // console.log(profileItem, "profileItem");
          if (
            profileItem.display_name &&
            profileItem.name &&
            profileItem.profile_image
          ) {
            // window.localStorage.setItem("Profile", JSON.stringify(profileItem));
            handleUpdateUserDetails();
            return;
          } else {
            setUserDetails((prevState) => ({
              ...prevState,
              showProfileEditPanel: true,
              displayName: profileItem.display_name || "",
              name: profileItem.name || "",
              mobileNo: profileItem.mobile || "",
              profileImg: profileItem.profile_image || "",
              updatedDisplayName: profileItem.display_name || "",
              updatedName: profileItem.name || "",
              updatedMobileNo: profileItem.mobile || "",
              updatedProfileImg:
                profileItem.profile_image ||
                "https://app.uwc.world/Content/App/assets/images/profile-img.png",
            }));
          }
        } else if (postResponse?.status === 400) {
          setResponse_message("Incorrect OTP!");
          setOtpInput(true);
          setTimeout(() => {
            setResponse_message("");
          }, 3000);
        }
      } catch (error) {
        ////console.log(error);
      }
    };
    postRecordingStatus();
  };

  const handleUserDetails = (e, field) => {
    e.preventDefault();

    setUserDetails((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
  };

  const handleChangeProfilePic = async (event) => {
    try {
      let file = Array.from(event.target.files)[0];

      if (
        file?.type === "image/png" ||
        file?.type === "image/jpg" ||
        file?.type === "image/jpeg"
      ) {
        const options = {
          maxSizeMB: 1, // (1 MB), adjust according to your needs
          maxWidthOrHeight: 1920, // adjust according to your needs
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        const dirName = dayjs().format("MMMM_YYYY");
        const storageRef = ref(storage, `${dirName}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, compressedFile);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            ////console.log(`Upload progress: ${percent}%`);
          },
          (error) => {
            console.error("Upload error:", error);
            // Handle upload error if needed
          },
          async () => {
            try {
              const url = await getDownloadURL(uploadTask.snapshot.ref);
              ////console.log("url", url);
              setUserDetails((prevState) => ({
                ...prevState,
                updatedProfileImg: url,
              }));
              //setProfilePicture(url)
            } catch (error) {
              console.error("Error getting download URL:", error);
              // Handle error if needed
            }
          }
        );
      } else {
        console.error("Incorrect file format.");
      }
    } catch (error) {
      console.error("Error handling file upload:", error);
      // Handle error if needed
    }
  };

  const handleUpdateUserDetails = async () => {
    const profileItem = profile;
    // console.log(userDetails, "user item");

    const newProfileItem = {
      display_name: userDetails?.updatedDisplayName || profileItem.display_name,
      email: profileItem.email,
      mobile: userDetails.updatedMobileNo || profileItem.mobile,
      name: userDetails.updatedName || profileItem.name,
      profile_image: userDetails.updatedProfileImg || profileItem.profile_image,
      user_id: profileItem.user_id,
    };
    const payload = {
      user_id: profileItem.user_id,
      type: 0,
      login_response: {
        email: profileItem?.email,
        type: "update_user",
        name: userDetails.updatedName || profileItem.name,
        profile_image:
          userDetails.updatedProfileImg || profileItem.profile_image,
        mobile: userDetails.updatedMobileNo || profileItem.mobile,
        display_name:
          userDetails?.updatedDisplayName || profileItem.display_name,
      },
      data_type: "",
      options: [],
      classify: "User: ",
      time_stamp: Date.now(),
    };
    // console.log(newProfileItem, "New Profile Item");
    updateUserDetails(payload);
    window.localStorage.setItem("Profile", JSON.stringify(newProfileItem));
  };

  // ////console.log("userDetails", userDetails);
  return (
    <div className={" "}>
      <header className="absolute inset-x-0 top-0 z-50">
        <Dialog
          as="div"
          className=" "
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <Dialog.Panel className="fixed   shadow-2xl h-full   background-gradient  pt-40  sm:pt-0 inset-y-0 right-0 z-50 w-full lg:w-1/2   sm:ring-1 sm:ring-gray-900/10">
            <img
              className="sm:block absolute hidden z-[-100] w-full h-full object-cover "
              src="../assets/Homepage.jpg"
            />
            <img
              src="../assets/uwcwhite.png"
              alt="Uwc logo"
              className=" absolute sm:hidden top-2 w-[300px] lg:w-[400px] p-4 "
            />
            <div className="absolute hidden sm:block inset-0 z-[-100]  opacity-50  background-gradient w-full "></div>
            <div className="flex absolute top-2  right-2 items-center justify-end">
              <button
                type="button"
                className=" m-1 rounded-md text-black sm:text-white"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-10 w-10" aria-hidden="true" />
              </button>
            </div>
            {/* Login form to be implemented */}
            {!otpInput && login && (
              <div className="flex  items-center h-full   bg-white sm:bg-transparent pt-10  rounded-t-3xl  sm:rounded-none flex-col justify-center px-6  ">
                <div className="h-[75vh] sm:h-full sm:flex items-center flex-col justify-center pb-12 sm:pb-0 overflow-y-scroll">
                  <div className="sm:mx-auto mt-6  sm:w-full  sm:max-w-sm">
                    {response_message && (
                      <div className="text-red-500 text-center mt-6 rounded-xl p-2 border">
                        {response_message}
                      </div>
                    )}
                    <h2 className=" text-center text-3xl sm:text-4xl  leading-9  sm:text-white text-black">
                      Welcome to Stella!
                    </h2>
                    <h2 className=" text-center text-3xl sm:text-4xl my-2 sm:my-5 leading-9  sm:text-white text-black ">
                      Log In
                    </h2>
                    <p className="text-center my-5 sm:my-3 border-2  sm:text-lg rounded-md sm:mt-10 text-black bg-white p-2">
                      <img
                        className="inline w-[24px] mr-1 sm:w-4"
                        src="../assets/GoogleIcon.png"
                        alt=""
                      />{" "}
                      Sign in with Google
                    </p>
                  </div>

                  <div className="sm:mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <div className="flex items-center ">
                      <span className="w-full h-[1px] bg-black sm:bg-white"></span>
                      <span className="mx-2 sm:text-white text-black  text-md">
                        Or
                      </span>
                      <span className="w-full h-[1px] bg-black sm:bg-white"></span>
                    </div>
                    <div className="mt-2 flex flex-col justify-center items-center gap-8">
                      <p className="text-center mt-2 sm:text-white text-black ">
                        Log in with your Email Address
                      </p>
                      <input
                        id="email"
                        name="email"
                        autoComplete="email"
                        placeholder="Email Address"
                        required={true}
                        type="email"
                        className="block w-full text-xl  rounded-md border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={(e) => {
                          // Check if the Enter key is pressed without the Shift key
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault(); // Prevent the default action to avoid line break in the textarea
                            handleSignIn();
                          }
                        }}
                      />
                      <button
                        className=" w-fit  min-w-[200px]   text-lg rounded-md bg-[#38AB45] p-3   leading-6 text-white shadow-sm "
                        onClick={() => handleSignIn()}
                        disabled={isLogin}
                      >
                        {isLogin ? (
                          <CircularProgress size={24} className="" />
                        ) : (
                          "Continue "
                        )}
                      </button>
                      <p className=" sm:text-white text-black">
                        Don’t have an account?{" "}
                        <span className=" font-semibold underline">
                          {" "}
                          Create Account
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="flex mt-12 gap-2 justify-center flex-col items-center">
                    <p className=" text-sm sm:text-white text-black">
                      We are internationally certified!
                    </p>
                    <div className="flex justify-center  items-center gap-4">
                      <img
                        src="../assets/iso.webp.png"
                        alt=""
                        className="inline"
                      />
                      <img
                        src="../assets/hippa.webp.png"
                        alt=""
                        className="inline"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {otpInput && (
              <div className=" h-full sm:flex justify-center items-center">
                <div className="flex w-full p-4 sm:w-3/4 lg:w-2/3 h-full bg-white sm:rounded-3xl rounded-t-3xl   sm:h-[350px] m-0   shadow-lg  flex-col  px-6  ">
                  <p className=" my-6 font-bold text-black text-2xl lg:text-4xl ">
                    Confirm OTP
                  </p>
                  <p className=" text-slate-500">
                    An OTP has been sent to your email{" "}
                    <span className="text-black font-bold">{email} </span>
                  </p>
                  <p className=" text-slate-500 my-2">
                    {" "}
                    Please enter the OTP to verify your email.
                  </p>
                  <div className="flex  items-center px-7 justify-center">
                    <div className="w-max mt-6">
                      <OtpInput length={4} onComplete={handleComplete} />
                      <div
                        className={`flex ${
                          resendotp && "text-[#73589B]"
                        } text-slate-500 items-center justify-between`}
                      >
                        <p>
                          {"00:"}
                          {timer > 9 ? timer : `0${timer}`}{" "}
                        </p>
                        <button
                          onClick={() => {
                            handleSignIn();
                            setTimer(30);
                            setResendOtp(true);
                          }}
                          disabled={resendotp}
                        >
                          Resend OTP
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex mt-20 sm:hidden gap-2 justify-center flex-col items-center">
                    <p className=" text-sm sm:text-white text-black">
                      We are internationally certified!
                    </p>
                    <div className="flex justify-center  items-center gap-4">
                      <img
                        src="../assets/iso.webp.png"
                        alt=""
                        className="inline"
                      />
                      <img
                        src="../assets/hippa.webp.png"
                        alt=""
                        className="inline"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {userDetails?.showProfileEditPanel && (
              <div className="h-full  sm:flex justify-center items-center">
                <div className="flex flex-col pt-20 gap-2 sm:gap-0 overflow-auto   w-full sm:w-3/4 lg:w-2/3 h-full sm:h-max p-6  sm:p-8 bg-white sm:rounded-3xl  rounded-t-3xl    shadow-lg">
                  {(userDetails?.profileImg === null ||
                    userDetails?.profileImg === "" ||
                    userDetails?.profileImg ===
                      "https://app.uwc.world/Content/App/assets/images/profile-img.png") && (
                    <div className="mx-auto">
                      <div className="mx-auto relative">
                        <input
                          id="profile-pic-upload"
                          type="file"
                          ref={uploadInputFileRef}
                          accept="image/*"
                          className="hidden"
                          onChange={handleChangeProfilePic}
                        />
                        <img
                          className="mx-auto h-24 w-auto aspect-square rounded-full"
                          src={userDetails.updatedProfileImg}
                          alt="Display Pic"
                        />

                        <button
                          className="p-2 rounded-full bg-[#73589B] absolute bottom-0 right-4"
                          htmlFor="profile-pic-upload"
                          onClick={() => {
                            uploadInputFileRef.current.click();
                          }}
                        >
                          <CameraIcon className="w-5 h-5 text-slate-50" />
                        </button>
                      </div>

                      <button
                        className="rounded-md bg-[#73589B] text-base font-semibold  text-white shadow-lg   hover:bg-[#73589ba9] mt-2 p-3 px-6  relative"
                        htmlFor="profile-pic-upload"
                        onClick={() => {
                          uploadInputFileRef.current.click();
                        }}
                      >
                        Upload Image
                      </button>
                    </div>
                  )}
                  {(userDetails?.displayName === "" ||
                    userDetails?.displayName === null) && (
                    <div className="mt-4 ">
                      <div className="mt-2">
                        <input
                          type="text"
                          name="text"
                          id="displayName"
                          value={userDetails.updatedDisplayName}
                          onChange={(e) =>
                            handleUserDetails(e, "updatedDisplayName")
                          }
                          className="block w-full text-xl  rounded-md  border-none p-3 text-black shadow-sm ring-[#73589B] ring-1 ring-inset outline-none  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                          placeholder=" Display Name"
                        />
                      </div>
                    </div>
                  )}
                  {(userDetails?.name === null ||
                    userDetails?.name === "" ||
                    userDetails?.name === "User") && (
                    <div className="mt-4">
                      <div className="mt-2">
                        <input
                          type="text"
                          name="text"
                          id="name"
                          value={userDetails.updatedName}
                          onChange={(e) => handleUserDetails(e, "updatedName")}
                          className="block w-full text-xl  rounded-md  border-none p-3 text-black shadow-sm ring-[#73589B] ring-1 ring-inset outline-none  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                  )}
                  {userDetails?.mobileNo === "" && (
                    <div className="mt-4">
                      <div className="flex justify-between">
                        <span
                          className="text-sm leading-6 text-gray-500"
                          id="mobile-optional"
                        >
                          Optional
                        </span>
                      </div>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="mobile"
                          id="mobile"
                          value={userDetails.updatedMobileNo}
                          onChange={(e) =>
                            handleUserDetails(e, "updatedMobileNo")
                          }
                          className="block w-full text-xl  rounded-md  border-none p-3 text-black shadow-sm ring-[#73589B] ring-1 ring-inset outline-none  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                          placeholder=" Mobile No"
                          aria-describedby="mobile-optional"
                        />
                      </div>
                    </div>
                  )}
                  <div className="my-6 py-4 lg:mt-6">
                    <button
                      type="submit"
                      disabled={disableDetailsSubmit}
                      className="flex w-full justify-center text-lg font-semibold  rounded-md bg-[#73589B] disabled:bg-[#73589b93] p-3   leading-6 text-white shadow-sm "
                      onClick={handleUpdateUserDetails}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Dialog.Panel>
        </Dialog>
      </header>

      <main>
        <img
          src="../assets/uwcwhite.png"
          alt="Uwc logo"
          className="w-[300px] lg:w-[400px] p-4 "
        />
        <div className="flex-col-reverse justify-center items-center flex lg:flex-row ">
          <div className=" max-w-7xl px-6">
            <div className="">
              <div className="relative w-full flex flex-col justify-center ">
                <h1 className="text-2xl font-bold  text-white sm:text-6xl">
                  Stella
                </h1>
                <h1 className="text-2xl font-bold  text-white sm:text-6xl">
                  24/7 Virtual AI
                </h1>
                <h1 className="text-2xl font-bold   text-white sm:text-6xl">
                  Mental Health First Aid
                </h1>
                <p className="mt-6 text-lg sm:text-2xl leading-8 text-white ">
                  Personalized Companion Throughout Your Care Journey
                </p>
                <div className="my-10   flex items-center gap-x-6">
                  <button
                    onClick={() => setMobileMenuOpen(true)}
                    className="rounded-md text-center w-full text-2xl lg:w-fit  font-semibold  shadow-sm  bg-white text-[#692ECB] p-3 px-5 "
                  >
                    Talk To Stella
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className=" max-w-7xl ">
            <img
              src="../assets/HomePageSvg.png"
              alt="hero"
              className="w-full h-full object-cover object-center"
            />
          </div>
        </div>
      </main>
    </div>
  );
}
