import React, { useState, useEffect, useRef } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { postRecordingStatus } from "../Functions/APIHelper";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
const HandleRecording = ({ chatCallBack, shouldchat }) => {
  const { transcript, finalTranscript, listening, resetTranscript } =
    useSpeechRecognition();
  const [startRecording, setStartRecording] = useState(false);
  const [showButton, setshowButton] = useState(false);

  useEffect(() => {
    if (shouldchat) {
      setshowButton(true);
      setStartRecording(true);
    }else{
      setshowButton(false);
      setStartRecording(false);
    }
  }, [shouldchat]);

  /**
   * useEffect hook that starts/stops speech recognition
   * based on startRecording state.
   * - When startRecording is true:
   *   - Resets transcript
   *   - Starts continuous speech recognition with english (India) language model
   *   - If final transcript is not empty:
   *     - Calls chatCallback with final transcript
   *     - Resets transcript
   * - When startRecording is false:
   *   - Stops speech recognition
   *   - Resets transcript
   */
  useEffect(() => {
    if (startRecording) {
      setshowButton(true);
      resetTranscript();
      // //console.log("Recording is on");
      SpeechRecognition.startListening({ language: "en-IN" });

      if (finalTranscript !== "") {
        chatCallBack({ chat: finalTranscript });
        resetTranscript();
        setshowButton(false);
        setStartRecording(false);
        SpeechRecognition.stopListening();
      }
    } else {
      SpeechRecognition.stopListening();
      resetTranscript();
      setshowButton(false);
      // //console.log("Recording is off");
    }
  }, [finalTranscript, startRecording]);

  return (
    <>
      {showButton
   
       ? (
        <button
          className={`w-[50px] aspect-square disabled:cursor-not-allowed cursor-pointer flex flex-col justify-center items-center text-white rounded-full ${
            startRecording ? "bg-[#73589B]" : "ActionEnd"
          }`}
          onClick={() => setStartRecording(false)}
          disabled={!shouldchat}
        >
          <MicNoneOutlinedIcon className="text-white" />
        </button>
      ) : (
        <button
          className={`w-[50px] aspect-square disabled:cursor-not-allowed cursor-pointer flex flex-col justify-center items-center  rounded-full `}
          onClick={() => setStartRecording(true)} 
          disabled={!shouldchat}
        >
          <MicOffOutlinedIcon className="text-white" />
        </button>
      )}
    </>
  );
};

export default HandleRecording;
