import "./App.css";
import { useState, Fragment, useEffect, useRef } from "react";
import { decodeToken } from "react-jwt";
import ReconnectingWebSocket from "reconnecting-websocket";
import VideoRecorder from "./Components/VideoRecorder";
import HandleRecording from "./Components/HandleRecording";
import { PlayCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import FavoriteIcon from "@mui/icons-material/Favorite";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import VideocamOffOutlinedIcon from "@mui/icons-material/VideocamOffOutlined";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import SpeakerNotesOffOutlinedIcon from "@mui/icons-material/SpeakerNotesOffOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import CallEndOutlinedIcon from "@mui/icons-material/CallEndOutlined";
import PixelStreaming from "./Components/PixelStreaming";
import {
  closePixelStreamInstance,
  runPixelStreamInstance,
} from "./Functions/APIHelper";
import "./index.css";
import HomePage from "./Components/HomePage";
import VideoModal from "./Modals/VideoModal";
import FeedbackModal from "./Feedback/FeedbackModal";

function App() {
  const [userID, setUserID] = useState("");
  const [token, SetToken] = useState("");
  const [isLogin, setisLogin] = useState(false);
  const socketRef = useRef(null);
  const [showHeartbeat, setShowHeartbeat] = useState(false);
  const [response_message, setResponse_Message] = useState();
  const [chatHistory, setChatHistory] = useState([]);
  const [startCamera, setStartCamera] = useState(false);
  const [showChat, setShowChat] = useState(false); //to be false testing
  const chatHistoryRef = useRef(null);
  const [processIds, setProcessIds] = useState({});
  const [stream, setStream] = useState(false);
  const [tempID, setTempID] = useState();
  const [error, seterror] = useState("");
  const [videoopen, setVideoOpen] = useState(false);
  const [closeStream, setCloseStream] = useState(false);
  const [message, setMessage] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [session_token, setsession_token] = useState(0);
  const [isLoginComplete, setIsLoginComplete] = useState(false);
  const [profile, setProfile] = useState({});
  const [chacterProfile, setChacterProfile] = useState({});
  const [shouldchat, setShouldchat] = useState(false);
  function generateRandomToken(length) {
    return window.crypto
      .getRandomValues(new Uint8Array(length))
      .reduce((acc, item) => acc + ("0" + item.toString(16)).slice(-2), "");
  }

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    let decodedToken;
    if (
      window.sessionStorage.getItem("token") === undefined ||
      window.sessionStorage.getItem("token") === "" ||
      window.sessionStorage.getItem("token") === null
    ) {
      fetch("https://socket.mystella.ai/stella/get-token", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          window.sessionStorage.setItem("token", result?.token);
          decodedToken = decodeToken(result?.token);
          // console.log(decodedToken);
          SetToken(result?.token);
          setUserID(decodedToken?.temp_user_id);
          setTempID(decodedToken?.temp_user_id);
        })
        .catch((error) => console.error("error", error));
    } else {
      decodedToken = decodeToken(window.sessionStorage.getItem("token"));
      if (decodedToken.user_id) {
        // console.log(decodedToken);
        setUserID(decodedToken?.user_id);
        setTempID(decodedToken?.temp_user_id);
        SetToken(window.sessionStorage.getItem("token"));
        // console.log(window.localStorage.getItem("Profile"));
        if (
          window.localStorage.getItem("Profile") !== undefined &&
          window.localStorage.getItem("Profile") !== "" &&
          window.localStorage.getItem("Profile") !== null
        ) {
          let userProfile = JSON.parse(window.localStorage.getItem("Profile"));
          // console.log(userProfile,"user APP profile");
          if (
            userProfile?.display_name &&
            userProfile.name &&
            userProfile.profile_image
          ) {
            setProfile(userProfile);
            setIsLoginComplete(true);
          }
        }
      } else {
        setUserID(decodedToken.temp_user_id);
        SetToken(window.sessionStorage.getItem("token"));
      }
    }
    setsession_token(generateRandomToken(16));
  }, []);
  // console.log(session_token);
  useEffect(() => {
    if (isLoginComplete) {
      setProfile(JSON.parse(window.localStorage.getItem("Profile")));
      LaunchprocessIds(); //This will launch pixel streaming
      setisLogin(true);
    }
  }, [token, isLoginComplete]);

  const LaunchprocessIds = async () => {
    const resp = await runPixelStreamInstance(tempID);
    // console.log(resp);
    if (!resp.message) {
      setTimeout(() => {
        setProcessIds(resp);

        // setStream(true);
      }, 5000);
    } else {
      seterror(resp.message);

      setTimeout(() => {
        seterror("");
        closePixelStreamInstance(
          processIds.serverProcessId,
          processIds.playerProcessId,
          processIds.httpsPort,
          processIds.streamerPort,
          processIds.httpPort,
          tempID
        );
        setStartCamera(false);
        setStream(false);
        setProcessIds({});
        setShowChat(false);
        setCloseStream(true);
        socketRef.current.close();
      }, 10000);
    }
  };
  // console.log(closeStream);
  useEffect(() => {
    if (token !== "") {
      if (!socketRef.current) {
        // Create a new WebSocket connection
        socketRef.current = new ReconnectingWebSocket(
          `wss://socket.mystella.ai/ws?authorization=${token}`
        );

        // Event handler for when the connection is opened
        socketRef.current.addEventListener("open", (event) => {
          console.log("WebSocket connection opened!");
        });

        // Event handler for when a message is received from the server

        socketRef.current.addEventListener("message", (event) => {
          let resp_data = JSON.parse(event.data);

          // console.log("resp_data", resp_data);

          if (resp_data.type === 0) {
            if (resp_data?.login_request?.success) {
              const newToken = resp_data?.token;
              // console.log("newToken", decodeToken(newToken));
              const profileDetails = resp_data?.login_request?.data

              window.sessionStorage.setItem("token", newToken);
              // window.localStorage.setItem("Profile", profileDetails);
              setProfile(profileDetails)
              // console.log("profileDetails", profileDetails);
              SetToken(newToken);
              const decodedToken = decodeToken(newToken);
              setUserID(decodedToken.user_id);
              setTempID(decodedToken.temp_user_id);

              setResponse_Message(resp_data?.login_request?.message);
              if (
                resp_data?.login_request?.data?.display_name !== null &&
                resp_data?.login_request?.data?.profile_image !==
                "https://app.uwc.world/Content/App/assets/images/profile-img.png"
              ) {
                // setIsLoginComplete(true);
              }
            } else {
              setResponse_Message(resp_data?.login_request?.message);
              setTimeout(() => {
                setResponse_Message("");
              }, 5000);
            }
          } else {
            setTimeout(() => {
              // console.log(resp_data);
              if (resp_data?.question === "") return;
              setChatHistory((prevData) => [
                ...prevData,
                { ...resp_data, liked: false },
              ]);
            }, 3000);
          }
        });

        // Event handler for when the connection is closed
        socketRef.current.addEventListener("close", (event) => {
          console.log("WebSocket connection closed!");
        });
      }
    }
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null; // Reset the ref when the component unmounts
      }
    };
  }, [token]);
  // console.log(chatHistory);

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const handleChatData = (data) => {
    if (data.chat === "" || data.option === null) return;
    let currDataNew = {
      service_id: chatHistory[chatHistory.length - 1]?.service_id || 50, // service identifier
      message_id: "",
      question_id: chatHistory[chatHistory.length - 1]?.question_id || 0, //question_id
      type: data?.option ? 1 : 2, // type 1 for text, 2 for option
      response: data?.chat || data?.option?.option_display_name, // option_display_name
      time_stamp: Date.now(),
      session_id: session_token,
      user_id: userID,
      classify: "User: ",
      responseFrom: "User",
      options: data.option,
    };
    setMessage("");
    socketRef.current.send(JSON.stringify(currDataNew));
    setShouldchat(false);
    // console.log("sent", JSON.stringify(currDataNew));
    // console.log(data);
    if (data?.optionIndex > -1 && data?.questionIndex > -1) {
      setChatHistory((prev) => {
        let temp = [...prev];
        temp[data?.questionIndex].options[data?.optionIndex].is_selected = true;
        // console.log(temp);
        return temp;
      });
    }

    setChatHistory((prev) => [...prev, currDataNew]);
  };

  const handleSignInData = (data) => {
    // console.log(data);
    if (socketRef.current) {
      let currData = {
        user_id: userID,
        type: 0,
        login_response: {
          email: data?.email,
          type: "send_otp",
        },
        data_type: "",
        classify: "User: ",
        time_stamp: Date.now(),
      };
      // console.log("Login", JSON.stringify(currData));
      socketRef.current.send(JSON.stringify(currData));
    }
  };
  const handleUserUpdate = (data) => {
    //console.log("DATA", data);
    socketRef.current.send(JSON.stringify(data));

    setIsLoginComplete(true);
  };

  return (
    <div
      className={`  relative ${processIds.httpsPort ? stream ? "bg-black" : "bg-[#DFE1E5]" : "background-gradient"
        }  min-h-[100vh] flex flex-col items-center justify-center`}
    >
      {!processIds.httpsPort && isLogin && !closeStream && !error && (
        <div className="absolute z-[100] top-0 left-0 w-full h-full ">
          <div className="pulse top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%]"></div>
        </div>
      )}

      {error && (
        <div className="absolute top-60 left-1/2 translate-x-[-50%] rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">{error}</h3>
            </div>
          </div>
        </div>
      )}
      <div className={"flex   justify-center  w-full h-full z-10"}>
        <div className=" w-full relative">
          <div
            className={`absolute top-[10%] w-36 sm:w-60 md:aspect-[3/4] aspect-[9/18] right-4 p-2 z-[50] `}
          >
            <VideoRecorder startCam={startCamera} sessionId={session_token} />
          </div>

          {processIds.httpsPort && (
            <PixelStreaming
              PORT={processIds?.httpsPort}
              tempID={tempID}
              selectedStreamMain={(data) => {
                // console.log("selected", data);

                setShouldchat(data.speak);
                if (data.selected) {
                  setStream(data.selected);
                  setShowChat(data.selected);

                  setChacterProfile({ image: data?.image, name: data?.name });
                  socketRef.current.send(
                    JSON.stringify({
                      service_id: 50, // service identifier
                      message_id: "",
                      question_id: "", //question_id
                      type: "",
                      response: "Hello! I am Stella", // option_display_name
                      time_stamp: Date.now(),
                      session_id: session_token,
                      user_id: userID,
                      classify: "User: ",
                      responseFrom: "User",
                    })
                  );
                }
              }}
            />
          )}
          {stream && (
            <>
              {showHeartbeat && (
                <ul className="hearts  z-[1000000] flying">
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                </ul>
              )}
              <div className="z-[100000] glass w-fit gap-6 bg-[#FFFFFFCC]   rounded-full  p-2 px-5 absolute bottom-4 sm:bottom-10 left-1/2 translate-x-[-50%] justify-evenly items-center  flex">
                <button
                  className={`w-[50px] aspect-square disabled:cursor-not-allowed cursor-pointer flex flex-col justify-center items-center rounded-full ${startCamera ? "bg-[#73589B]" : ""
                    }`}
                    disabled={!shouldchat}
                  onClick={() => setStartCamera(!startCamera)}
                >
                  {startCamera ? (
                    <VideocamOutlinedIcon className={`text-white` }/>
                  ) : (
                    <VideocamOffOutlinedIcon className={`text-white `} />
                  )}
                </button>
                <HandleRecording
                  chatCallBack={handleChatData}
                  shouldchat={shouldchat}
                />
                <div
                  className={`w-[50px]  aspect-square cursor-pointer flex flex-col justify-center items-center rounded-full ${showChat ? " bg-[#73589B]" : ""
                    }`}
                  onClick={() => {
                    setShowChat(!showChat);
                  }}
                >
                  {showChat ? (
                    <ChatOutlinedIcon className="text-white" />
                  ) : (
                    <SpeakerNotesOffOutlinedIcon className="text-white" />
                  )}
                  {/* <ChatBubbleLeftEllipsisIcon className="h-6 w-6 text-[#9158CE] mx-auto" /> */}
                </div>
                <div
                  onClick={() => {
                    closePixelStreamInstance(
                      processIds.serverProcessId,
                      processIds.playerProcessId,
                      processIds.httpsPort,
                      processIds.streamerPort,
                      processIds.httpPort,
                      tempID
                    );
                    setStartCamera(false);
                    setStream(false);
                    setProcessIds({});
                    setShowChat(false);
                    setCloseStream(true);
                    socketRef.current.close();
                  }}
                  className="w-[50px] bg-red-600 aspect-square cursor-pointer flex flex-col justify-center items-center rounded-full"
                >
                  <CallEndOutlinedIcon className=" text-white rounded-full " />
                </div>
              </div>
            </>
          )}

          {stream && (
            <button
            disabled={!shouldchat}
              onClick={() => {
                setShowHeartbeat(!showHeartbeat);
                let currDataNew = {
                  service_id: chatHistory[chatHistory.length - 1]?.service_id || 50, // service identifier
                  message_id: "",
                  question_id: chatHistory[chatHistory.length - 1]?.question_id || 0, //question_id
                  type: 2,
                  response: "sending you some love...", // option_display_name
                  time_stamp: Date.now(),
                  session_id: session_token,
                  user_id: userID,
                  classify: "User: ",
                  responseFrom: "User",
                  options: null,
                };
                setMessage("");
                setShouldchat(false);
                socketRef.current.send(JSON.stringify(currDataNew));
                setTimeout(() => {
                  setShowHeartbeat(false);
                }, 6000);
              }}
              className="z-[100000] flex w-[40px] h-[40px] disabled:cursor-not-allowed sm:w-fit sm:h-fit bg-[#ff5b5b] rounded-full absolute  bottom-24 cursor-pointer shadow-lg shadow-[#fe6c6c9d]  sm:bottom-11 sm:p-5 right-6 justify-evenly items-center"
            >
              <FavoriteIcon className="text-white" />
            </button>
          )}
        </div>

        {showChat && (
          <div className="absolute lg:relative lg:bg-white pt-60 lg:pt-0 m-0 lg:bottom-0 bottom-16 right-0 z-50 w-full lg:w-5/12   sm:ring-1 sm:ring-gray-900/10">
            <div className="hidden lg:flex items-center  justify-between w-full   px-6 py-4">
              <h3 className=" text-xl font-bold ">Chat</h3>
              {/* <button
                type="button"
                className="  rounded-md p-2.5 text-black "
                onClick={() => setShowChat(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-10 w-10" aria-hidden="true" />
              </button> */}
            </div>

            <div className=" ">
              <div
                ref={chatHistoryRef}
                className="  lg:h-[80vh] h-[30vh]    overflow-scroll  lg:bg-[#cccaca3f] m-0  rounded-t-xl  md:rounded-none  p-4 "
              >
                {chatHistory?.map((item, i) => {
                  return (
                    <div key={i} className="p-2">
                      <div
                        className={`flex ${item?.classify?.includes("User") &&
                          " flex-row-reverse"
                          }    lg:text-black  mb-2 gap-2  m-0`}
                      >
                        <div className="flex items-center gap-2"></div>
                        {item?.classify?.includes("Therapist") ? (
                          <img
                            src={chacterProfile?.image}
                            alt=""
                            className="w-[40px] h-[40px] object-cover aspect-square rounded-full border border-solid border-white"
                          />
                        ) : (
                          item?.classify?.includes("User") && (
                            <img
                              src={profile?.profile_image}
                              className="w-[40px] h-[40px]  object-cover rounded-full border border-solid border-white"
                              alt=""
                            />
                          )
                        )}

                        <div>
                          <div
                            className={`${item.media_type == "video" ||
                              item.media_type == "audio"
                              ? " rounded-2xl p-4 "
                              : ""
                              }  rounded-xl ${item?.classify?.includes("User")
                                ? "lg:bg-[#73589B]   text-white"
                                : "lg:bg-white lg:text-black text-white lg:shadow-[#73589B14] lg:shadow-xl"
                              } bg  p-4 pt-3`}
                          >
                            {item?.classify?.includes("User") ? (
                              <p className="mb-2 text-[14px] text-right  font-bold">
                                {profile.display_name}
                              </p>
                            ) : (
                              <p className="text-[14px] text-left font-bold mb-2">
                                {chacterProfile?.name}
                              </p>
                            )}
                            {(item.media_type == "video" ||
                              item.media_type == "audio") &&
                              item.thumbnail_img !== undefined && (
                                <div
                                  className="relative w-fit  hover:cursor-pointer"
                                  onClick={() => {
                                    setThumbnailUrl(item.thumbnail_img);
                                    setVideoUrl(item.video_url);
                                    setVideoOpen(true);
                                  }}
                                >
                                  <img
                                    src={item?.thumbnail_img}
                                    className="w-full  rounded-3xl mb-2"
                                  />
                                  <PlayCircleIcon className="text-white/80  w-8 h-8 absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] rounded-full" />
                                </div>
                              )}
                            {item.audio_url &&
                              item.thumbnail_img == undefined && (
                                <audio
                                  src={item.audio_url}
                                  controls={true}
                                  className="w-full min-w-[250px]"
                                />
                              )}
                            <div
                              className="highlightHTML break-words break-all"
                              dangerouslySetInnerHTML={{ __html: item?.html }}
                            />
                            {/* <span className="text-left text-[14px]">
                              {item?.question || item?.response}
                            </span> */}
                            {item?.classify?.includes("User") ? (
                              <p className="mb-2 text-[14px] text-left  ">
                                {item?.response}
                              </p>
                            ) : (
                              <p className="text-[14px]  text-left  mb-2">
                                {item?.question}
                              </p>
                            )}
                            {item?.options?.length > 0 && (
                              <div className="flex gap-2 flex-wrap my-2">
                                {item.options.map((option, index) => (
                                  <button
                                    key={index}
                                    onClick={() =>
                                      handleChatData({
                                        option: option,
                                        questionIndex: i,
                                        optionIndex: index,
                                      })
                                    }
                                    className={` text-sm min-w-[100px] ${option?.is_selected
                                      ? "bg-[#73589B]  text-white"
                                      : " border border-[#73589B]  text-[#73589B] bg-white"
                                      }  rounded-full px-4 p-2 `}
                                  >
                                    {option.option_display_name}
                                  </button>
                                ))}
                              </div>
                            )}
                          </div>
                          <p className="text-right mt-1 hidden lg:block text-[12px] text-[#00000099]">
                            {new Date(item?.time_stamp).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div></div>
              </div>
              <div className="p-8  py-3 bg-white hidden absolute bottom-0 w-full lg:flex justify-center gap-3 items-center">
                <input
                  name="message"
                  id="message"
                  className="block p-4 disabled:cursor-not-allowed text-xl w-full border resize-none rounded-full bg-transparent  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="Message..."
                  disabled={!shouldchat}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleChatData({ chat: message });
                    }
                  }}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <div>
                  {" "}
                  <button
                    onClick={() => handleChatData({ chat: message })}
                    disabled={!shouldchat}
                    className="flex disabled:cursor-not-allowed  items-center rounded-full bg-[#73589B] p-3 text-sm font-semibold text-white shadow-sm  "
                  >
                    <SendRoundedIcon className=" rotate-[-45deg] translate-x-[2px]   " />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <div ref={heartsRef} className="hearts"></div> */}
      {!processIds.httpsPort && !isLogin && !stream && !closeStream && (
        <HomePage
          updateUserDetails={handleUserUpdate}
          loginCallback={handleSignInData}
          response={response_message}
          profile={profile}
        />
      )}

      {closeStream && (
        <FeedbackModal
          submit={(data) => {
            setCloseStream(data);
            window.location.reload();
          }}
        />
      )}
      <VideoModal
        isOpen={videoopen}
        closeCallback={() => setVideoOpen(false)}
        source={videoUrl}
        thumbnail={thumbnailUrl}
      />
    </div>
  );
}

export default App;
