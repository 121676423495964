import { useState, useEffect, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import axios from "axios";

const VideoRecorder = ({ startCam, sessionId }) => {
  const webcamRef = useRef(null);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    let stream = null;

    const startCamera = async () => {
      try {
        const constraints = { video: true, audio: false };
        stream = await navigator.mediaDevices.getUserMedia(constraints);
        webcamRef.current.srcObject = stream;
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    };

    const stopCamera = () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
        stream = null;
        //console.log("Camera stopped");
      }
    };

    if (startCam) {
      startCamera();
    } else {
      stopCamera();
    }

    // Clean up by stopping the video stream when the component unmounts or startCam changes
    return () => {
      stopCamera();
    };
  }, [startCam]);

  const capture = useCallback(() => {
    if (startCam) {
      const imageSrc = webcamRef?.current?.getScreenshot();
      if (imageSrc) {
        const headers = {
          Authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
        };
        const data = { base64_image: imageSrc, session_id: sessionId };
        // //console.log("imageSrc", imageSrc);
        axios
          .post("https://socket.mystella.ai/stella/upload_image", data, {
            headers,
          })
          .then((response) => {
            // //console.log("Image uploaded successfully:", response.data);
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
          });
      }
    }
  }, [startCam]);

  useEffect(() => {
    let id;

    if (startCam) {
      id = setInterval(() => {
        capture();
      }, 1000);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }

    // Set the new interval ID
    setIntervalId(id);

    // Clear the interval on component unmount
    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [startCam]);

  return (
    <>
      {startCam && (
        <Webcam
          ref={webcamRef}
          audio={false}
          mirrored={true}
          height={12}
          className="webcam z-[90] rounded-md "
        />
      )}
    </>
  );
};

export default VideoRecorder;
